import axios from 'axios'
import React, { useState, useEffect, useCallback } from 'react'
import { useLangContext } from '../context/lang.context'
import { s, sInput, sInputError, sFormTextBottom } from '../style'
import { validateEmail } from '../utils'
import Button from './Button'
import { sContainer, sFormContainer } from './FormDynamic'
import FormFeedback from './FormFeedback'
import FormAgreements from './FormAgreements'
import Grid from './Grid'
import Headline from './Headline'
import { SectionBackground } from './Section'

const content = {
  title: {
    en: 'Newsletter',
    es: 'Newsletter',
    ro: 'Newsletter',
  },
  desc: {
    en: 'Subscribe and gain more. Join the subscribers of the Omida Logistics newsletter. Stay up to date with news and TFL offers.',
    es: 'Suscríbete y obtén más. Únase a los suscriptores del boletín de Omida Logística. Manténgase actualizado con noticias y ofertas de TFL',
    ro: 'Abonați-vă și câștigați mai mult. Alăturați-vă abonaților newsletter-ului Omida Logistics. Rămâneți la curent cu știrile și ofertele TFL.',
  },
  success: {
    title: { en: 'Confirm the subscription!', es: '¡Confirmar suscripción!', ro: 'Confirmați subscripţie!', },
    text: {
      en: 'Just one more step! We have sent you an email with subscription confirmation. Cannot see the message? Check the spam folder.',
      es: '¡Un paso más! A la dirección de correo electrónico facilitada, le hemos enviado un correo electrónico confirmando la suscripción a la newsletter. Si no ve el mensaje, por favor revise su casilla de correo no deseado.',
      ro: 'Încă un pas! V-am trimis un e-mail cu confirmarea abonamentului. Nu puteți vedea mesajul? Verificați folderul de spam.',
    },
  },
  failure: {
    title: { en: 'Failure!', es: '¡Falla!', ro: 'Eșec!', },
    text: {
      en: 'It looks like your message has not been sent. Contact us by e-mail or call us',
      es: 'Parece que su mensaje no ha sido enviado. Contáctenos por correo electrónico o por teléfono',
      ro: 'Se pare că mesajul tău nu a fost trimis. Contactați-ne prin e-mail sau sunați-ne',
    },
  },
  already: {
    title: { en: 'Subscribing!', es: '¡Te estás suscribiendo!', ro: '', },
    text: {
      en: 'Your email address has already been added to the Omida newsletter subscribers. The newest posts will appear on your mailbox soon!',
      es: 'Su dirección de correo electrónico está en la lista de suscriptores del boletín de noticias de Omida. ¡Pronto aparecerá la información más reciente en su buzón!',
      ro: 'Adresa ta de e-mail a fost deja adăugată la abonații la buletinul informativ Omida. Cele mai noi postări vor apărea în căsuța poștală în curând!',
    },
  },
  dictonary: {
    fill: {
      en: 'Enter email',
      es: 'Entrar email',
      ro: 'Introdu e-mail',
    },
    send: {
      en: 'Subscribe',
      es: 'Suscribir',
      ro: 'Subscribe',
    },
    fillagreements: {
      en: 'Agree to the terms',
      es: 'Seleccionar consentimiento',
      ro: 'De acord cu termenii',
    },
  },
  agreements: [
    {
      en: '* I agree to receive by e-mail commercial information coming from Omida Logistics Sp. z o.o. within the meaning of the Act of 18 July 2002 on electronic services',
      es: '* Acepto recibir información comercial por medios electrónicos en el sentido de la Ley de 18 de julio de 2002 sobre prestación de servicios electrónicos con contenidos de Omida Logistics Sp. z o.o.',
      ro: '* Sunt de acord să primesc prin e-mail informații comerciale provenite de la Omida Logistics Sp. z o.o. în sensul Legii din 18 iulie 2002 privind serviciile electronice',
    },
    {
      en: 'I hereby consent to the use by Omida Logistics Sp. z o.o. of telecommunications terminal equipment and automated calling systems to present commercial information within the meaning of the Act of 16 July 2014. -Telecommunications Law',
      es: 'Consiento el uso por parte de Omida Logistics Sp. z o.o. equipos terminales de telecomunicaciones y sistemas automáticos de llamada para presentar información comercial en el sentido de lo dispuesto en la Ley de 16 de julio de 2014 - Ley de Telecomunicaciones',
      ro: 'Sunt de acord cu utilizarea de către Omida Logistics Sp. z o.o. a echipamentelor terminale de telecomunicații și a sistemelor automate de apelare pentru a prezenta informații comerciale în sensul Legii din 16 iulie 2014. -Legea telecomunicațiilor',
    },
  ],
  administrating: {
    en: 'The administrator of your personal data will be Omida Logistics Sp. z o.o.(hereinafter: Omida). You can contact us as follows: by letter to: Aleja Grunwaldzka 472C, 80-309 Gdańsk, by e-mail:',
    es: 'El administrador de sus datos personales será Omida Logistics Sp. z o.o. (en adelante: Omida). Puede ponerse en contacto con nosotros de la siguiente manera: por carta a la siguiente dirección: Aleja Grunwaldzka 472C, 80-309 Gdańsk, por correo electrónico:',
    ro: 'Administratorul datelor dumneavoastră personale va fi Omida Logistics Sp. z o.o. (în continuare: Omida). Ne puteți contacta după cum urmează: prin scrisoare către: Aleja Grunwaldzka 472C, 80-309 Gdańsk, prin e-mail:',
  },
  administratingSecond: {
    en: 'or by phone: +48 58 741 88 14. Personal data will be processed in order to prepare offers on Omida products and services. Details about the processing of your personal data are available',
    es: ' o por teléfono: +48 58 741 88 14. Trataremos los datos personales para preparar ofertas de productos y servicios de Omida. Puede encontrar información detallada sobre el procesamiento de datos personales',
    ro: ' sau la telefon: +48 58 741 88 14. Datele personale vor fi prelucrate în vederea pregătirii ofertelor pentru produsele și serviciile Omida. Detalii despre prelucrarea datelor dumneavoastră cu caracter personal sunt disponibile',
  },
  administratingLinkText: {
    en: 'here',
    es: 'aquí',
    ro: 'aici',
  },
}

const FormNewsletter = () => {
  const { lang } = useLangContext()
  const [email, setEmail] = useState('')
  const [mailState, setMailState] = useState('unfilled')
  const [success, setSuccess] = useState(null)
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [wasSent, setWasSent] = useState(false)
  const initialFormAgreements = content.agreements.map((e) => false)
  const [formAgreements, setFormAgreements] = useState(initialFormAgreements)
  const agreementFilled = formAgreements[0]
  const [code, setCode] = useState(0)

  const validate = useCallback(() => {
    return validateEmail(email) && agreementFilled
  }, [email, agreementFilled])

  useEffect(() => {
    if (validate()) setMailState('filled')
    else setMailState('unfilled')
  }, [validate, setMailState])

  const onSend = (e) => {
    setSuccess(null)
    e.preventDefault()
    setWasSent(true)
    if (validate()) {
      setSuccess(null)
      setOpenBackdrop(true)
      setMailState('loading')
      axios({
        method: 'POST',
        url:
          process.env.NODE_ENV === 'development'
            ? 'http://localhost:5000/omida/newsletter/init'
            : 'https://cleverserver.appclever.pl/omida/newsletter/init',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        data: {
          email,
          campaignToken: 'Z2Guw',
          lang,
        },
      })
        .then((res) => {
          setSuccess(true)
          setEmail('')
          setOpenBackdrop(true)
          setWasSent(false)
          setCode(res.data.code)
          setFormAgreements([false])
        })
        .catch((error) => {
          console.log(error)
          setOpenBackdrop(true)
          setSuccess(false)
        })
    }
  }

  return (
    <>
      <SectionBackground mobile top pbXL>
        <div css={sContainer}>
          <Headline
            medium
            gradient
            maxWidth
            title={content.title[lang]}
            desc={content.desc[lang]}
          />
          <form onSubmit={onSend}>
            <Grid
              container
              ccss={[
                sFormContainer,
                { marginBottom: '4rem', [s.sm_down]: { marginBottom: '1rem' } },
              ]}>
              <Grid item xs={12}>
                <input
                  type='email'
                  name='email'
                  placeholder='Email *'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  css={[
                    sInput,
                    wasSent && !validateEmail(email) && sInputError,
                    { marginBottom: 0, [s.md]: { marginBottom: 0 } },
                  ]}
                />
              </Grid>
            </Grid>
            <FormAgreements
              data={content.agreements}
              formAgreements={formAgreements}
              setFormAgreements={setFormAgreements}
              wasSent={wasSent}
            />
            <div css={sFormTextBottom}>
              <p>
                {content.administrating[lang]}{' '}
                <a href='mailto:bok@omida.pl'>bok@omida.pl</a>
                {content.administratingSecond[lang]}{' '}
                <strong>
                  <a
                    href={'/pdf/forms_en.pdf'}
                    target='_blank'
                    rel='noreferrer'>
                    {content.administratingLinkText[lang]}
                  </a>
                </strong>
                .
              </p>
            </div>
            <Button
              noMargin
              center
              reactiveBlack
              active={wasSent ? validateEmail(email) : true}
              googleId={'send_button'}
              extraCss={{
                marginTop: '4rem',
                [s.sm_down]: { marginTop: '2rem' },
              }}
              action={onSend}>
              {wasSent && mailState !== 'filled'
                ? !agreementFilled
                  ? content.dictonary.fillagreements[lang]
                  : content.dictonary.fill[lang]
                : content.dictonary.send[lang]}
            </Button>
          </form>
        </div>
      </SectionBackground>
      {openBackdrop && (
        <FormFeedback
          success={success}
          open={openBackdrop}
          setOpen={setOpenBackdrop}
          dataSuccess={code === 2 ? content.already : content.success}
          dataFailure={content.failure}
        />
      )}
    </>
  )
}

export default FormNewsletter
