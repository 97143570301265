export const seo = {
  url: 'contact',
  title: {
    en: 'Contact with the transport company. Experts in transport, forwarding and logistics',
    es: 'Contactar con la empresa de transporte. Expertos en transporte, expedición y logística',
    ro: 'Contact cu firma de transport. Experți în transport, expediere și logistică',
  },
  desc: {
    en: 'Get in touch with the logistics experts. More than 20 branches throughout Poland are responsible for transport throughout Europe.',
    es: 'Póngase en contacto con los expertos en logística. Más de 20 sucursales en toda Polonia son responsables del transporte en toda Europa.',
    ro: 'Luați legătura cu experții în logistică. Peste 20 de filiale din Polonia sunt responsabile de transportul în toată Europa.',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    en: 'Contact',
    es: 'Contacto',
    ro: 'Contact',
  },
  desc: {
    en: `
    <span>
      <strong>OMIDA LOGISTICS Sp. z o.o.</strong></br>
      AL. GRUNWALDZKA 472C<br/>
      80-309 GDAŃSK, POLAND<br/>
      <br/>
      tel. +48 58 741 88 14</br>
      fax. +48 58 741 88 14<br/>
      <br/>
      VAT: PL5842739582<br/>
      REGON: 360603722<br/>
      KRS: 0000539585<br/>
    </span>
    `,
    es: `
    <span>
    <strong>OMIDA LOGISTICS Sp. z o.o.</strong></br>
    AL. GRUNWALDZKA 472C<br/>
    80-309 GDAŃSK, POLONIA<br/>
    <br/>
    tel. +48 58 741 88 14</br>
    fax. +48 58 741 88 14<br/>
    <br/>
    VAT: PL5842739582<br/>
    REGON: 360603722<br/>
    KRS: 0000539585<br/>
  </span>
    `,
    ro: `
    <span>
    <strong>OMIDA LOGISTICS Sp. z o.o.</strong></br>
    AL. GRUNWALDZKA 472C<br/>
    80-309 GDAŃSK, POLONIA<br/>
    <br/>
    tel. +48 58 741 88 14</br>
    fax. +48 58 741 88 14<br/>
    <br/>
    VAT: PL5842739582<br/>
    REGON: 360603722<br/>
    KRS: 0000539585<br/>
  </span>
    `,
  },
}

export const main = {
  title: {
    en: 'Contact with the transport company',
    es: 'Contacta con la empresa de transporte',
  },
  texts: [
    {
      en: 'Begin a conversation with experts in the field of transport, forwarding and logistics. Find the right shipping department or branch. Use a quick inquiry, quote a transport, establish cooperation or join a network of carriers - display the forms by clicking the button on the right side of the screen.',
      es: 'Comience una conversación con expertos en el campo del transporte, expedición y logística. Encuentre el departamento o sucursal de envío adecuado. Utilice una consulta rápida, cotice un transporte, establezca una cooperación o únase a una red de transportistas: muestre los formularios haciendo clic en el botón en el lado derecho de la pantalla.',
    },
  ],
}
